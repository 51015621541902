<div class="question bounceInUp">
    <p class="question__heading">{question.question}</p>
    <img class="question__image" style="animation: {animation} 1.5s" src="{question.resource.url}" alt="{question.question}" />
    <ul class="question__option-list no-bullet clearfix">
    {#each question.options as option}
        <li class="question__option-item {correctAnswer(question, option, totalTries)}" on:click='answer(option)'>
            {option}
        </li>
    {/each}
    </ul>
    
    {#if tries}
    <p class="question__explanation">{question.explanation}</p>
    <button class="question__next" on:click='fire( "next", { question: question })'>Next</button>
    {/if}
</div>

<script>
    let animations = [
        'zoomIn','flipInY',
        'fadeIn','bounceIn',
        'flipInX','lightSpeedIn'
    ];

    let randomAnimation  = () => animations[Math.floor(Math.random() * animations.length)];

    export default {
        helpers: {
            correctAnswer(question, answer, totalTries) {
                if (question.tries >= totalTries && question.answer == answer) {
                    return 'question__option-item-answer';
                }
                return '';
            }
        },
        onstate({ changed, current, previous }) {
            if (changed.question) {
                this.set({animation: randomAnimation()});
            }
        },
        methods: {
            answer(option) {
                let { question } = this.get();
                if (question.tries >= this.get('totalTries')) return;
                this.fire("answer", {option});
            }
        },
        computed: {
            tries: ({ question, totalTries }) => { 
                return question.tries >= totalTries;
            },
            triesLeft: ({ question, totalTries }) => {
                if (question.tries === undefined) {
                    question.tries = 0;
                }
                return (totalTries - question.tries)
            }
        },
        data() {
            return {
                animation: randomAnimation()
            }
        }
    }
</script>

<style>
.question__heading {
    font-size: 3.5rem;
    padding: 20px;
    text-align: center;
}
.question__image {
    display: block;
    height: 300px;
    width: auto;
    margin: 40px auto;
}

.question__option-item {
    width: 43%;
    margin: 2%;
    font-size: 2.5rem;
    padding: 10px;
    display: inline-block;
    float: left;

    border: 5px solid #e1f5c4;
    border-radius: 30%/90%;
    text-align: center;
    text-transform: lowercase;
    cursor: pointer;
    transition: box-shadow 0.5s, background-color 0.5s;
}
.question__option-item:hover {
    animation: pulse 1s infinite;
    box-shadow: 0 3px 1px 1px rgba(0,0,0,0.2);
    background-color: #e1f5c4;
}
.question__next {
    background-color: #e1f5c4;
    border: 5px solid #add86d;
    border-radius: 30%/90%;
    color: #000;
    text-align: center;
    text-transform: lowercase;
    cursor: pointer;
    font-size: 1.5rem;
    padding: 10px;
    width: 160px;
    margin: 40px auto;
    display: block;
}
.question__tries-left {
    text-align: center;
}
.question__option-item-answer {
    box-shadow: 0 3px 1px 1px rgba(0,0,0,0.2);
    background-color: #79cc00;
}
.question__explanation {
    font-size: 1.9rem;
    text-align: center;
}
</style>