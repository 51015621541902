<div class="question-set">
    {#if question}
        <Question 
            on:answer='checkAnswer(event.option)'
            on:next='onToNext(event.question)'
            question='{question}'
            totalTries='3'
        />
    {:else}
        <Feedback questions='{questions}' totalPoints='3' />
        <button class="question__reset" on:click='reset()'>Play Again</button>
    {/if}
</div>

<script>
    import Question from './Question.html';
    import Feedback from './Feedback.html';
    import { randomQuestion, addTriesToQuestion, addPointToQuestion, resetQuestions } from '../checker.js';

    export default {
        methods: {
            checkAnswer(answer) {
                let { question, questions } = this.get();
                addTriesToQuestion(question);

                if (answer === question.answer) {
                    addPointToQuestion(question);
                    question = randomQuestion(questions);
                } 
                this.set({question});
            },
            onToNext(prevQuestion) {
                let { questions } = this.get();
                addPointToQuestion(prevQuestion);
                let question = randomQuestion(questions);
                this.set({question});
            },
            reset() {
                let { questions } = this.get();
                questions = resetQuestions(questions);
                let question = randomQuestion(questions);
                this.set({questions, question});
            }
        },
        data() {
            return {
                question: {},
                questions: {}
            }
        },
        components: {
            Question,
            Feedback
        }
    }
</script>

<style>
    .question-set {
        margin-top: 20px;
    }
    .question__reset {
        background-color: #e1f5c4;
        border: 5px solid #add86d;
        border-radius: 30%/90%;
        color: #000;
        text-align: center;
        text-transform: capitalize;
        cursor: pointer;
        font-size: 1.5rem;
        padding: 10px;
        width: 160px;
        margin: 40px auto;
        display: block;
    }
</style>